.header__contact {
  background: {
    image: url("../images/contato.jpg");
    repeat: no-repeat;
    size: cover;
    position: top center;
  }

  &__normalizer {
    height: 32rem;
  }

  &__title {
    font: {
      style: normal;
      size: 3.5rem;
      weight: 400;
    }
    color: $pink;
  }

  &__subtitle {
    color: $blue;
    line-height: 30px;
    width: 26rem;
  }
}

.form {
  text-align: start;
  padding: 0 12rem;

  &__check {
    color: $blue;
    margin-top: 1rem;
  }

  &__btn__pink{
    color: $white;
    background-color: $pink !important;

    &:hover {
      background-color: $pink;
      color: $white;
    }
  }
}
