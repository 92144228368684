.form {
  &--transparent {
    .form {
      &-floating {
        label {
          color: $white;
        }
      }

      &-control {
        color: $white;

        background: {
          color: transparent;
        }
      }
    }
  }
}
