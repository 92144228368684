@mixin transition {
  transition: 0.2s;
}

@mixin shadow {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.81);
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.22);
}

@mixin padding-block {
  padding: {
    top: 5rem;
    bottom: 5rem;
  }

  @include media-breakpoint-down(md) {
    padding: {
      top: 3rem;
      bottom: 3rem;
    }
  }
}
