.sub__header {
  background-color: $grayBackground;
  height: 4rem;

  &__content__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;

    &__text {
      font: {
        style: normal;
        size: 0.8rem;
        weight: 400;
      }
      text-transform: uppercase;
      color: $gray;
      margin-bottom: 0;
      padding: 0 1.2rem;

      &:hover {
        color: $green;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;

    &__text {
      font: {
        style: normal;
        size: 0.9rem;
        weight: 400;
      }
      text-transform: uppercase;
      color: $gray;
      margin-bottom: 0;
    }
  }
}
