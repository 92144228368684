.query {
  &__header{
    background: {
      image: url("../images/consultas_topicos.png");
      repeat: no-repeat;
      size: cover;
      position: top center;
    }

    &__normalizer {
      height: 20rem;
    }

    &__title {
      font: {
        style: normal;
        size: 3rem;
        weight: 400;
      }

      margin-bottom: 0;
      color: $blue;
    }

    &__value {
      font: {
        size: 1rem;
        weight: 600;
      }

      margin-bottom: 0;
      color: $blue;
    }

    &__subtitle {
      color: $blue;
      line-height: 30px;
      max-width: 26rem;
    }
  }

  &__content {
    color: $blue;
    opacity: .8;

    margin: {
      top: 3rem;
    }
  }

  &__description {
    margin: {
      bottom: 2rem;
    }
  }

  &__cta {
    color: $white;
    padding: 2rem;

    @include border-radius;

    margin: {
      top: 3rem;
    }

    background: {
      color: $darkGrayBackground;
    }

    &__block {
      @include media-breakpoint-down(md) {
        order: -1 !important;
      }
    }

    &__title {
      font-size: 1.4rem;
      margin-bottom: .1rem;
    }

    &__description {
      margin-bottom: 3rem;
    }

    &--btn {
      margin-top: 0 !important;

      @include media-breakpoint-down(sm) {
        margin-top: .7rem !important;
      }
    }

    .btn {
      margin-top: .7rem;
    }
  }
}
