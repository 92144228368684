.toast {
  right: 1rem !important;
  bottom: 1rem !important;

  p,
  ul {
    margin: 0;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;
  }

  &--red {
    background-color: $red;
    color: $white;
  }

  &--green {
    background-color: $green;
    color: $white;
  }

  &--yellow {
    background-color: $yellow;
  }
}
