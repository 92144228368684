.gray-background {
  background-color: $grayBackground;
}

.soft-gray-background {
  background-color: $lightGrayBackground;
}

.admin-background {
  background-color: $grayAdminBackground;
}
