.header__how__consult {
  background: {
    image: url("../images/como_consultar.png");
    repeat: no-repeat;
    size: cover;
    position: top center;
  }

  &__normalizer {
    height: 32rem;
  }

  &__title {
    font: {
      style: normal;
      size: 3.5rem;
      weight: 400;
    }
    color: $green;
  }

  &__subtitle {
    color: $blue;
    line-height: 30px;
    //   width: 20rem;
  }
}

.header__consult {
  background: {
    image: url("../images/consultas.png");
    repeat: no-repeat;
    size: cover;
    position: top center;
  }

  @include media-breakpoint-down(md) {
    background: {
      image: none;
      color: $lightGrayBackground;
    }

    padding: {
      top: 2rem;
      bottom: 2rem;
    }
  }

  &__normalizer {
    height: 32rem;

    @include media-breakpoint-down(md) {
      height: 0;
    }
  }

  &__title {
    font: {
      style: normal;
      size: 3.5rem;
      weight: 400;
    }
    color: $blue;
  }

  &__subtitle {
    color: $blue;
    line-height: 30px;
    //   width: 20rem;
  }
}

.sub__header {
  background-color: $grayBackground;
  height: 4rem;

  &__content__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;

    &__text {
      font: {
        style: normal;
        size: 0.8rem;
        weight: 400;
      }
      text-transform: uppercase;
      color: $gray;
      margin-bottom: 0;
      padding: 0 1.2rem;

      &:hover {
        color: $green;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;

    &__text {
      font: {
        style: normal;
        size: 0.9rem;
        weight: 400;
      }
      text-transform: uppercase;
      color: $gray;
      margin-bottom: 0;
    }
  }
}

.content__consult {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;

  &__title {
    font: {
      style: normal;
      size: 1.8rem;
      weight: 400;
    }
    color: $green;
    line-height: 30px;
  }

  &__description {
    font: {
      style: normal;
      size: 1rem;
      weight: 400;
    }
    color: $blue;
    line-height: 25px;
    padding: 0 15rem;
  }
}

.card__section {
  text-align: center;
  margin-top: 4rem;

  @include media-breakpoint-down(md) {
    padding: {
      left: 2rem;
      right: 2rem;
    }
  }

  &__title {
    font: {
      style: normal;
      size: 1.8rem;
      weight: 400;
    }
    color: $green;
    line-height: 30px;
    margin-top: 4rem;
  }

  .card {
    margin: 1rem;
    width: 17.5vw;
  }

  .card__grid {
    display: flex;
    justify-content: center;
  }

  .card__content {
    height: 17rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $grayBorder;
    border-radius: 8px;
    margin: 1rem;

    &__title {
      color: $green;
      font: {
        style: normal;
        size: 1.2rem;
        weight: 400;
      }
      margin-bottom: 1rem;
    }

    &__amount {
      color: $gray;
      font: {
        style: normal;
        size: 0.8rem;
        weight: 400;
      }
    }

    &__description {
      color: $blue;
      font: {
        style: normal;
        size: 0.9rem;
        weight: 400;
      }
      padding-bottom: 2rem;
    }

    &__button {
      background-color: $green;
      font: {
        style: normal;
        size: 0.8rem;
        weight: 400;
      }
      color: $white;
      text-transform: uppercase;
      border-radius: 10px;
      padding: 0.6rem;
      &:hover {
        color: $white!important;
      }
    }
  }
}

.nav-tabs {
  display: flex;
  justify-content: center;
}

.nav-text {
  color: $blue !important;
}

.nav-tabs .nav-text.active  {
  color: $green !important;
}

.tab-content {
  &__image {
    width: 100%;
    height: 24rem;
    padding: 2rem 6rem;
  }

  &__small {
    color: $green;
    margin-top: 2rem;
    font: {
      style: normal;
      size: 0.9rem;
      weight: 400;
    }
    text-transform: uppercase;
  }

  &__title {
    font: {
      style: normal;
      size: 1.5rem;
      weight: 400;
    }
    color: $green;
    line-height: 30px;

  }

  &__description {
    font: {
      style: normal;
      size: 0.9rem;
      weight: 400;
    }
    color: $blue;
    line-height: 26px;
  }

  &__button {
    // display: flex;
    // flex-direction: column;
    // justify-content: end;
    // align-items: center;
    margin-top: 2.5rem;
  }

  &__link {
    background-color: $green;
    font: {
      style: normal;
      size: 0.8rem;
      weight: 400;
    }
    color: $white;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 0.6rem 3rem;
    &:hover {
      color: $white!important;
    }
  }
}

.accordion {
  padding: 2rem 14rem;
}

.accordion-button {
  font-size: 0.8rem;
  color: $gray;
}

.accordion-body {
  color: $blue;
  margin: 1rem 0;
}

.accordion-item {
  border: none;
}


.accordion-button:not(.collapsed) {
  color: $green;
  background-color: $white;
}

.card__register {
  background: {
    image: url("../images/cta.png");
    repeat: no-repeat;
    size: cover;
    position: top center;
  }

  border-radius: 9px;
  width: 43.75rem;
  height: 22rem;
  display: flex;
  margin-bottom: 3rem;

  @include media-breakpoint-down(md) {
    height: 29rem;
  }

  @include media-breakpoint-down(sm) {
    width: unset;
    display: block;
    height: auto;

    margin: {
      top: 2rem;
    }

    background: {
      image: none;
      color: $lightGrayBackground;
    }
  }

  &__content {
    padding: 6rem 2rem;

    @include media-breakpoint-down(sm) {
      padding: {
        top: 3rem;
        bottom: 1.5rem;
      }
    }
  }

  &__title {
    font: {
      style: normal;
      size: 1.5rem;
      weight: 400;
    }
    color: $green;
    line-height: 30px;

  }

  &__description {
    font: {
      style: normal;
      size: 0.9rem;
      weight: 400;
    }

    color: $blue;
    line-height: 26px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 2.5rem;

    @include media-breakpoint-down(sm) {
      margin: 0;
      padding-bottom: 3rem;
    }
  }

  &__link {
    background-color: $green;
    font: {
      style: normal;
      size: 0.8rem;
      weight: 400;
    }
    color: $white;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 0.6rem 6rem;
    &:hover {
      color: $white!important;
    }
  }
}
