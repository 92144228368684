/*=======================================
-> Colors
=======================================*/
// Pinks
$pink:                          #FF1B5A;

//Green
$green:                         #61a140;

// Blues
$darkerBlue:                    #002E50;
$blue:                          #012E50;
$textBlue:                      #7a838a;

// Whites
$white:                         #ffffff;

// Grays
$gray:                          #6F899C;
$grayBackground:                #F8F8F8;
$grayBorder:                    #eaeaea;
$darkGrayBackground:            #677c8c;
$lightGrayBackground:           #f2f2f2;
$grayAdminBackground:           #f2f4f8;

/*=======================================
-> Borders
=======================================*/
$border-radius:               .275rem;
$border-radius-sm:            .25rem;
$border-radius-lg:            .5rem;
$border-radius-xl:            1rem;
$border-radius-2xl:           2rem;
$border-radius-pill:          50rem;
