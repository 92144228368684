.header {
  background: {
    size: cover;
    repeat: no-repeat;
    position: top center;
    image: url("../images/homepage_1.png");
  }

  .container {
    padding: {
      top: 7rem;
      bottom: 7rem;
    }

    @include media-breakpoint-down(md) {
      padding: {
        top: 3rem;
        bottom: 3rem;
      }
    }
  }

  &__title {
    color: $white;
    max-width: 20rem;

    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
      margin: 0 auto;
    }
  }

  &__subtitle {
    margin: 0;
    color: $white;
    font-weight: 600;
    max-width: 25rem;

    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
      margin: 0 auto;
    }
  }

  .grid-queries {
    max-width: 28rem;
    padding: 2.3rem;

    background: {
      color: $white;
    }

    @include media-breakpoint-down(md) {
      margin: 2rem auto 0;
    }

    &__title {
      padding: .4rem;
      text-align: center;
      margin-bottom: .5rem;
      background-color: #094858;

      h6 {
        margin: 0;
        color: #fff;
        font-weight: 600;
      }
    }

    &__query {
      margin-bottom: .5rem;
      border-bottom: 1px solid $gray;

      p {
        margin: 0;

        font: {
          size: .9rem;
        }
      }
    }
  }
}

.how-to {
  text-align: center;

  @include padding-block;

  .section__title {
    margin-bottom: 1.5rem;
  }

  .section__subtitle {
    max-width: 40rem;
    display: block;
    margin: 0 auto 4rem auto;
  }

  &__block {
    border-right: 1px solid $grayBorder;

    padding: {
      top: 3rem;
      bottom: 3rem;
    }

    &:last-child {
      border-right: unset;
    }

    @include media-breakpoint-down(xl) {
      &:nth-child(3) {
        border-right: none;
      }
    }

    @include media-breakpoint-down(md) {
      border-right: none;

      padding: {
        top: 1.5rem;
        bottom: 1.5rem;
      }
    }

    &__small {
      color: $green;
      display: block;
      font-size: .75rem;
      text-transform: uppercase;

      margin: {
        top: 1rem;
        bottom: .5rem;
      }
    }

    &__title {
      color: $green;

      font: {
        weight: 600;
        size: 1.2rem;
      }
    }

    &__subtitle {
      width: 15rem;
      margin: 0 auto 0 auto;
      display: block;
    }
  }
}

.payment {
  padding: {
    top: 7rem;
    bottom: 7rem;
  }

  @include media-breakpoint-down(lg) {
    padding: {
      top: 3rem;
      bottom: 3rem;
    }
  }

  h5 {
    font: {
      size: 2rem;
      weight: 700;
    }
  }

  p {
    max-width: 18rem;
    line-height: 1.2;

    font: {
      size: 1.8rem;
      weight: 300;
    }

    margin: {
      top: 2rem;
      bottom: 0;
    }

    margin: {
      top: 1rem;
      left: auto;
      right: auto;
    }
  }

  &__left {
    img {
      width: 6rem;
      margin-right: 1rem;
    }

    @include media-breakpoint-down(lg) {
      .d-flex {
        justify-content: center;
      }

      p {
        text-align: center;
        margin-bottom: 3rem;
      }
    }
  }

  &__image {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  &__right {
    text-align: right;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }

    h5 {
      @include media-breakpoint-down(lg) {
        margin-top: 3rem;
      }
    }

    p {
      max-width: 24rem;

      margin: {
        left: auto;
      }
    }
  }
}

.register {
  @include padding-block;

  .section__title {
    margin-bottom: 3rem;
  }

  .btn {
    margin: 0 auto;
    display: block;
  }
}

.doubts {
  @include padding-block;

  .section__title {
    margin-bottom: 3rem;
  }

  .accordion {
    padding: unset;

    &-item {
      margin-bottom: 1rem;
      border-radius: 0;

      &:focus {
        border-radius: 0;

        background: {
          color: $gray;
        }
      }

      &:first-of-type {
        .accordion-button {
          border-radius: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
