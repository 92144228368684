.admin {
  &__header {
    color: $white;

    padding: {
      top: 3rem;
      bottom: 3rem;
    }

    background: {
      color: $pink;
    }

    p {
      margin: 0;
    }
  }

  &__navbar {
    background: {
      color: $blue !important;
    }

    .navbar {
      &-nav {
        .nav {
          &-item {
            &:first-child {
              .nav-link {
                padding: {
                  left: 0;
                }
              }
            }
          }

          &-link {
            color: $white;

            padding: {
              left: 2rem;
              right: 2rem;
            }

            svg {
              margin: {
                bottom: 0.25rem;
                right: .5rem;
              }
            }
          }
        }
      }
    }
  }

  &__content {
    margin: {
      top: 3.5rem;
      bottom: 2rem;
    }
  }

  &__aside {
    h6 {
      color: $textBlue;
    }
  }

  &__card {
    color: $textBlue;
    padding: 1.9rem;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);

    border: {
      radius: 0.2rem;
    }

    background: {
      color: $white;
    }

    margin: {
      bottom: 1.5rem;
    }

    .dropdown-toggle {
      svg {
        color: $textBlue;
      }

      &::after {
        display: none;
      }
    }

    &__title {
      font: {
        weight: 600;
      }

      small {
        font: {
          size: .9rem;
        }
      }
    }

    &__icon {
      height: 2.5rem;
      width: 2.5rem;
      transition: .2s;

      svg {
        color: $white;
      }

      &--blue {
        background: {
          color: lighten($darkerBlue, 30);
        }
      }

      &--red {
        background: {
          color: lighten($pink, 30);
        }

        &:hover {
          background: {
            color: $pink;
          }
        }
      }
    }

    &__value {
      max-width: 70%;

      font: {
        size: 1.9rem;
        weight: 700;
      }

      margin: {
        bottom: 1.3rem;
      }

      small {
        font: {
          size: 50%;
        }
      }
    }

    &__percentage {
      margin: {
        right: .5rem;
      }

      &--green {
        color: $green;
      }

      &--red {
        color: $red;
      }

      svg {
        margin: {
          bottom: .1rem;
        }
      }
    }

    &__line {
      border-bottom: {
        width: .1rem;
        style: solid;
        color: $lightGrayBackground;
      }

      &:last-child {
        border-bottom: {
          width: .1rem;
          style: solid;
          color: transparent;
        }
      }

      .line {
        &__icon {
          &__outline {
            height: 2.5rem;
            width: 2.5rem;

            background: {
              color: $lightGrayBackground;
            }

            border: {
              radius: 2.5rem;
            }
          }
        }

        &__text {
          color: $textBlue;
          vertical-align: middle;

          padding: {
            top: 1rem;
            left: .25rem;
            right: .25rem;
            bottom: 1rem;
          }

          &--green {
            color: $green;
          }

          &--red {
            color: $red;
          }

          &--yellow {
            color: $yellow;
          }
        }
      }
    }
  }

  &__body {
    padding: 2rem;
    color: $textBlue;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);

    border: {
      radius: 0.2rem;
    }

    background: {
      color: $white;
    }

    &__title {
      font: {
        size: 1.5rem;
        weight: 600;
      }
    }

    .accordion {
      padding: 0;

      &-item {
        border: {
          width: .1rem;
          style: solid;
          color: $grayBorder;
        }

        &:first-child {
          border: {
            width: .1rem;
            style: solid;
            color: $grayBorder;
          }
        }

        &:last-child {
          border-bottom: {
            width: .1rem;
            style: solid;
            color: $grayBorder;
          }
        }


      }
    }
  }
}
