.header__about {
  background: {
    image: url("../images/sobre_nos.png");
    repeat: no-repeat;
    size: cover;
    position: top center;
  }

  @include media-breakpoint-down(md) {
    background: {
      image: none;
      color: $lightGrayBackground;
    }

    padding: {
      top: 2rem;
      bottom: 2rem;
    }
  }

  &__normalizer {
    height: 32rem;

    @include media-breakpoint-down(md) {
      height: 0;
    }
  }

  &__title {
    font: {
      style: normal;
      size: 3.5rem;
      weight: 400;
    }
    color: $blue;
  }

  &__subtitle {
    color: $blue;
    line-height: 30px;
    width: 26rem;

    @include media-breakpoint-down(md) {
      width: unset;
    }
  }
}

.pleasure {
  text-align: center;
  margin-top: 2rem;


  &__title {
    color: $green;
    font: {
      style: normal;
      size: 2rem;
      weight: 400;
    }
  }

  &__subtitle {
    color: $blue;

    font: {
      style: normal;
      size: 1rem;
      width: 20rem;
    }
    padding: 1rem 0rem;
    line-height: 35px;
    width: 42rem;
    margin: 0 auto;
    margin-bottom: 2rem;

    @include media-breakpoint-down(lg) {
      width: unset;
    }
  }

  &__title__start {
    color: $green;
    font: {
      style: normal;
      size: 2rem;
      weight: 400;
    }
    text-align: start;
    margin-left: 2rem;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }

  &__subtitle__start {
    color: $blue;
    font: {
      style: normal;
      size: 1rem;
    }
    padding: 1rem 0rem;
    line-height: 28px;
    text-align: start;
    margin-left: 2rem;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }

  &__img {
    padding-right: 1rem;
    height: 29rem;
    margin-bottom: 3rem;

    background: {
      image: url("../images/sobre_nos_02.png");
      repeat: no-repeat;
      size: cover;
      position: top center;
    }

    @include media-breakpoint-down(lg) {
      height: 25rem;
      margin-bottom: 3rem;
    }
  }

  &__itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 4rem;

    @include media-breakpoint-down(lg) {
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }

  &__itens__title {
    color: $green;
    font: {
      style: normal;
      size: 1.5rem;
      weight: 400;
    }
    line-height: 3rem;
  }

  &__itens__subtitle {
    color: $blue;
    font: {
      style: normal;
      size: 1rem;
    }
    padding: 0rem 2rem;
    line-height: 25px;
  }

}
