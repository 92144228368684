.navbar {
  padding: 1rem 0;

  background: {
    color: $white !important;
  }

  &-brand {
    img {
      width: 14rem;
    }
  }

  .nav {
    &__form {
      .btn {
        padding: .9rem 0;
      }
    }

    &-button {
      border-radius: 10px;
      color: $white !important;
      text-transform: uppercase;

      background: {
        color: $green;
      }

      padding: {
        left: 1rem;
        right: 1rem;
        top: .5rem;
        bottom: .5rem;
      }

      font: {
        style: normal;
        size: 0.8rem;
        weight: 400;
      }

      &.active {
        color: $white !important;

      }

      &:hover {
        color: $white!important;
      }
    }
  }
}
