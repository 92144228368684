.footer {
  @include padding-block;

  &__content {
    img {
      width: 13rem;
      margin-bottom: 2rem;
    }

    p {
      max-width: 31rem;
    }
  }

  &__nav {
    svg {
      margin-bottom: 2rem;

      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }

    &__title {
      color: $gray;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        margin-top: 1rem;
      }
    }

    .nav {
      &-link {
        color: $gray;

        padding: {
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}

.rights {
  p {
    color: $gray;
  }
}
