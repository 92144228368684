@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

body {
  font-family: 'Titillium Web', sans-serif;
  line-height: 1.2em;
  text-decoration: none;
  color: $blue;

  h1 {
    letter-spacing: -1px;
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.section__title {
  color: $blue;
  text-align: center;

  font: {
    size: 2rem;
    weight: 700;
  }
}
