.modal {
  &-content {
    background: {
      color: transparent;
    }

    border: {
      width: 0;
    }
  }

  &-header {
    justify-content: end;

    padding: {
      right: 0;
      bottom: .5rem;
    }

    &--transparent {
      border: 0;
    }
  }

  &-body {
    padding: 3rem;

    background: {
      color: $white;
    }

    &--transparent {
      background: {
        color: transparent;
      }
    }
  }

  &__title {
    margin-bottom: 1.5rem;

    h6 {
      font: {
        size: 2rem;
        weight: 600;
      }
    }
  }
}
