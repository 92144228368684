.btn {
  padding: 1rem;
  text-transform: uppercase;

  &--fluid {
    width: 100%;
    text-align: center;
  }

  &--wide {
    padding: {
      left: 4rem;
      right: 4rem;
    }
  }

  &--red {
    color: $white;
    background: {
      color: $pink;
    }

    &:hover {
      color: $white !important;
      background: {
        color: lighten($pink, 10%);
      }
    }
  }

  &--blue {
    color: $white;
    background: {
      color: $blue;
    }

    &:hover {
      color: $white !important;
      background: {
        color: lighten($blue, 10%);
      }
    }
  }

  &--green {
    color: $white;
    background: {
      color: $green;
    }

    &:hover {
      color: $white !important;
      background: {
        color: lighten($green, 10%);
      }
    }
  }

  &--white {
    color: $blue;

    background: {
      color: $white;
    }

    &:hover {
      color: $white !important;
      background: {
        color: darken($white, 15%);
      }
    }
  }

  &--gray {
    color: $white;
    background: {
      color: $gray;
    }

    &:hover {
      color: $white !important;
      background: {
        color: lighten($gray, 10%);
      }
    }
  }
}
