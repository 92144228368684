.key-page {
  background: {
    size: contain;
    position: center right;
    repeat: no-repeat;
    attachment: fixed;
  }

  margin: {
    bottom: -1rem;
  }

  @include media-breakpoint-down(lg) {
    background: none;
  }

  &--big {
    .key-page__content {
      padding: {
        top: 14rem;
        bottom: 14rem;
      }
    }
  }

  &__content {
    background: {
      color: $white;
    }

    padding: {
      left: 12rem;
      right: 6rem;
      top: 7rem;
      bottom: 7rem;
    }

    @include media-breakpoint-down(lg) {
      padding: {
        left: 6rem;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 4rem;
    }

    @include media-breakpoint-down(sm) {
      padding: {
        left: 2rem;
        right: 2rem;
      }
    }

    &__title {
      color: $green;

      font: {
        size: 1.56rem;
        weight: 400;
      }
    }

    &__subtitle {
      margin: {
        bottom: 3rem;
      }
    }

    p {
      opacity: .8;
      color: $blue;

      font: {
        size: .9rem;
      }
    }
  }

  &__image {
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    }

    min-height: 95vh;
  }
}
